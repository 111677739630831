<template>
  <div>
    <div class="header-site">
      <router-link to="/">
        <b-button style="display: flex; background-color: white; color: black">
          Back</b-button
        >
      </router-link>
      <h1>Export Marketing Site Data</h1>
    </div>

    <div class="filters-buttons">
      <input
        type="text"
        v-model="siteIDFilter"
        placeholder="Filter by Site ID"
      />
      <input
        type="text"
        v-model="styleNameFilter"
        placeholder="Filter by Style Name"
      />
      <button class="btn btn-primary" @click="exportData">
        Export Selected
      </button>
    </div>

    <table
      class="table table-striped table-bordered mt-2"
      v-if="filteredSites.length && !loading"
    >
      <thead class="thead-light">
        <tr>
          <th>Select</th>
          <th>Site ID</th>
          <th>Style Name</th>
          <th>Primary Color (Hex)</th>
          <th>Primary Logo</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="site in filteredSites" :key="site.siteID">
          <td>
            <input
              type="checkbox"
              :value="site.siteID"
              v-model="selectedSiteIDs"
            />
          </td>
          <td>{{ site.siteID }}</td>
          <td>{{ site.styleName }}</td>
          <td>{{ convertRgbaToHex(site.primaryColor) }}</td>
          <td>
            <div v-if="site.primaryLogo[0]?.uuid">
              <img
                :src="
                  `https://media.rocketalumnisolutions.com/${site.primaryLogo[0].uuid}/`
                "
                alt="Primary Logo"
                style="max-width: 100px; max-height: 30px"
              />
            </div>
            <small v-else>No logo available</small>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-else>
      <p>{{ loading ? "Fetching all sites, this may take a moment..." : "No sites found." }}</p>
    </div>
  </div>
</template>

<script>
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { PAGE_SIZE } from "@/utils/constants";

export default {
  name: "ExportSites",
  data() {
    return {
      siteIDFilter: "",
      styleNameFilter: "",
      selectedSiteIDs: [],
      allSites: [],
      loading: false
    };
  },
  mounted() {
    this.loading = true;

    this.$api.get(`/userSiteIDMetadata`).then(res => {
      this.allSites = res.data;
      this.loading = false;
    });
  },
  computed: {
    filteredSites() {
      const idText = this.siteIDFilter.trim().toLowerCase();
      const nameText = this.styleNameFilter.trim().toLowerCase();

      return this.allSites.filter(site => {
        const matchesID = site.siteID
          .toString()
          .toLowerCase()
          .includes(idText);
        const matchesName = site.styleName
          .toString()
          .toLowerCase()
          .includes(nameText);
        return matchesID && matchesName;
      });
    },
    paginatedSites() {
      const currPage = this.$store.getters.getSitesPagination.currentPage;
      const start = Math.max(1, currPage * PAGE_SIZE - PAGE_SIZE + 1);
      return this.filteredSites.slice(start, start + PAGE_SIZE);
    }
  },
  methods: {
    convertRgbaToHex(rgbaString) {
      if (!rgbaString) return "N/A";
      const match = rgbaString.match(/rgba?\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)/);
      if (!match) return "N/A";

      // Destructure the array and use padStart for padding
      const [, r, g, b] = match;
      const toHex = num =>
        Number(num)
          .toString(16)
          .padStart(2, "0");

      return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
    },
    getSelectedSites() {
      return this.allSites.filter(s => this.selectedSiteIDs.includes(s.siteID));
    },
    async exportData() {
      let selectedSites = this.getSelectedSites();
      if (!selectedSites.length) {
        if (
          confirm(
            "No sites selected. Are you sure you want to export all sites?"
          )
        ) {
          selectedSites = this.allSites;
        } else {
          return;
        }
      }
      const rows = selectedSites.map(site => {
        const styleName = `"${site.styleName.replace(/"/g, '""')}"`;
        const siteUrl = `https://site.rocketalumnisolutions.com/home/${site.siteID}`;
        const primaryColorHex = site.primaryColor
          ? this.convertRgbaToHex(site.primaryColor)
          : "N/A";
        const primaryLogo = site.primaryLogo[0]
          ? `https://media.rocketalumnisolutions.com/${site.primaryLogo[0]?.uuid}/`
          : "N/A";
        return [styleName, siteUrl, primaryColorHex, primaryLogo];
      });

      const headerRow = [
        "Style Name",
        "Site URL",
        "Primary Color (Hex)",
        "Primary Logo"
      ];
      const csvContent = [headerRow, ...rows]
        .map(row => row.join(","))
        .join("\n");

      // Create and trigger the CSV download (similar to exportTableAsCSV)
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "selectedSites.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Now create a ZIP folder with primary logos
      const zip = new JSZip();
      const folder = zip.folder("primary-logos");

      await Promise.all(
        selectedSites.map(async site => {
          const logo = site.primaryLogo[0];
          if (!logo?.uuid) return;
          const primaryLogoUrl = `https://media.rocketalumnisolutions.com/${logo.uuid}/`;
          try {
            const response = await fetch(primaryLogoUrl, { mode: "cors" });
            if (!response.ok) {
              console.error(`Unable to fetch ${primaryLogoUrl}`);
              return;
            }
            const imageBlob = await response.blob();
            const ext = logo.mimeType.split("/")[1];
            folder.file(`site_${site.siteID}.${ext}`, imageBlob, {
              binary: true
            });
          } catch (err) {
            console.error("Error fetching logo:", err);
          }
        })
      );

      zip
        .generateAsync({ type: "blob" })
        .then(content => {
          saveAs(content, "primary-logos.zip");
        })
        .catch(err => {
          console.error("Error generating zip", err);
        });
    }
  }
};
</script>

<style scoped>
.header-site {
  padding: 20px;
}
.filters-buttons {
  display: flex;
  gap: 1rem;
  margin: 10px;
}

.filters-buttons input {
  padding: 0.3rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

table {
  width: 100%;
}

th,
td {
  vertical-align: middle;
  text-align: left;
}
</style>
